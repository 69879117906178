import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TimelineItemTyp, TimelineType } from '../../common/components/curafida-timeline/timeline.entity';
import { PlanerItemResource } from '../entities/planer-item-resource';
import { ExerciseSessionState, ExerciseSessionUserState } from '../../therapy/entities/exerciseSession';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { TherapyState } from '../../therapy/entities/therapy';

@Pipe({
    standalone: true,
    name: 'patientJournalItemDescription',
    pure: true,
})
export class PatientJournalItemDescriptionPipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(item: PlanerItemResource): string {
        const formattedTimeLineDate = format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm');
        if (
            item.type === TimelineItemTyp.LEARNING ||
            item.type === TimelineItemTyp.LEARNING_LEGACY ||
            item.type === TimelineItemTyp.TASK
        ) {
            return this.learningOrTaskDescription(item);
        }
        if (item.timelineDate && item.type === TimelineItemTyp.COURSE) {
            return this.courseDescription(item);
        }
        if (item.type === TimelineItemTyp.EXAMINATION) {
            return this.translateService.instant('STATE_DETAILS.CREATED_AT', {
                date: formattedTimeLineDate,
            });
        }
        if (item.type === TimelineItemTyp.TRAINING) {
            return this.trainingDescription(item);
        }
        if (
            item.type === TimelineItemTyp.USER_CREATED ||
            item.type === TimelineItemTyp.USER_UPDATED ||
            item.type === TimelineItemTyp.USER_CUSTOM_PROP_CHANGED ||
            item.type === TimelineItemTyp.TAG_ASSIGNED ||
            item.type === TimelineItemTyp.NOTE_CREATED ||
            item.type === TimelineItemTyp.PATIENT_REPORT_CREATED ||
            item.type === TimelineItemTyp.CREATE_CONSULTATION_PDF_REPORT ||
            item.type === TimelineItemTyp.CREATE_ORDER_PDF_REPORT
        ) {
            return this.timestampWithInitiatorDescription(item, 'CREATED');
        }
        if (
            item.type === TimelineItemTyp.USER_DELETED ||
            item.type === TimelineItemTyp.ROLES_CHANGED ||
            item.type === TimelineItemTyp.SUPERVISORS_CHANGED ||
            item.type === TimelineItemTyp.CAREGIVERS_CHANGED ||
            item.type === TimelineItemTyp.NOTE_CHANGED ||
            item.type === TimelineType.UPDATE_EMAIL_EVENT
        ) {
            return this.timestampWithInitiatorDescription(item, 'CHANGED');
        }
        if (item.type === TimelineItemTyp.TAG_UNASSIGNED || item.type === TimelineItemTyp.NOTE_DELETED) {
            return this.timestampWithInitiatorDescription(item, 'REMOVED');
        }
        return ''; // Default is empty description
    }

    private learningOrTaskDescription(item: PlanerItemResource) {
        if (item.exerciseSessionUserState === ExerciseSessionUserState.FINISHED) {
            return this.translateService.instant('STATE_DETAILS.COMPLETED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        if (
            [ExerciseSessionState.PATIENT_CANCELLED.toString(), ExerciseSessionState.CANCELLED.toString()].includes(
                item.exerciseSessionUserState,
            )
        ) {
            return this.translateService.instant('STATE_DETAILS.CANCELLED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        if (item.hasPreconditions && !item.arePreconditionsCompleted) {
            return this.translateService.instant('STATE_DETAILS.INACTIVE_CAUSE_OF_PRECONDITION');
        }
        if (
            [
                ExerciseSessionState.PLANNED.toString(),
                ExerciseSessionState.NOT_PLANNED.toString(),
                ExerciseSessionState.ACTIVE.toString(),
            ].includes(item.exerciseSessionUserState)
        ) {
            if (item.delayedTime && !item.calendarEventStartDate) {
                return this.translateService.instant('STATE_DETAILS.DUE_AT', {
                    date: format(new Date(item.delayedTime), 'dd.MM.yyyy, HH:mm', { locale: de }),
                });
            }
            if (!item.delayedTime && item.calendarEventStartDate) {
                return this.translateService.instant('STATE_DETAILS.APPOINTMENT_AT', {
                    date: format(new Date(item.calendarEventStartDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
                });
            }
            if (item.delayedTime && item.calendarEventStartDate) {
                return (
                    this.translateService.instant('STATE_DETAILS.DUE_AT', {
                        date: format(new Date(item.delayedTime), 'dd.MM.yyyy, HH:mm', { locale: de }),
                    }) +
                    ',\n' +
                    this.translateService.instant('STATE_DETAILS.APPOINTMENT_AT', {
                        date: format(new Date(item.calendarEventStartDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
                    })
                );
            }
            return;
        }
        return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
            date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
        });
    }

    private courseDescription(item: PlanerItemResource) {
        if (
            [ExerciseSessionState.PATIENT_CANCELLED.toString(), ExerciseSessionState.CANCELLED.toString()].includes(
                item.state,
            )
        ) {
            return this.translateService.instant('STATE_DETAILS.CANCELLED_AT_NOTYPE', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        } else if ([ExerciseSessionUserState.NO_SHOW].includes(item.exerciseSessionUserState)) {
            return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        }
        return this.translateService.instant('STATE_DETAILS.START_AT', {
            date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
        });
    }

    private trainingDescription(item: PlanerItemResource) {
        if ([TherapyState.LOCKED.toString()].includes(item.state)) {
            return this.translateService.instant('STATE_DETAILS.LOCKED', {
                date: format(new Date(item.delayedTime), 'dd.MM.yyyy', { locale: de }),
            });
        }
        if ([TherapyState.PLANNED.toString(), TherapyState.ACTIVE.toString()].includes(item.state)) {
            return this.translateService.instant('STATE_DETAILS.TRAINING', {
                completed: item.completedTraining,
                planned: item.plannedTraining,
            });
        } else if ([TherapyState.NO_SHOW.toString()].includes(item.exerciseSessionUserState)) {
            return this.translateService.instant('STATE_DETAILS.EXPIRED_AT', {
                date: format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm', { locale: de }),
            });
        } else {
            return this.translateService.instant('STATE_DETAILS.TRAINING', {
                completed: item.completedTraining,
                planned: item.plannedTraining,
            });
        }
    }

    private timestampWithInitiatorDescription(
        item: PlanerItemResource,
        action: 'CREATED' | 'CHANGED' | 'REMOVED',
    ): string {
        const formattedTimeLineDate = format(new Date(item.timelineDate), 'dd.MM.yyyy, HH:mm');
        let description = '';
        switch (action) {
            case 'CREATED':
                description = this.translateService.instant('JOURNAL.CREATED_TIMESTAMP', {
                    date: formattedTimeLineDate,
                });
                break;
            case 'CHANGED':
                description = this.translateService.instant('JOURNAL.CHANGED_TIMESTAMP', {
                    date: formattedTimeLineDate,
                });
                break;
            case 'REMOVED':
                description = this.translateService.instant('JOURNAL.REMOVED_TIMESTAMP', {
                    date: formattedTimeLineDate,
                });
                break;
            default:
                throw new Error('Unsupported action');
        }
        if (item.fullName) {
            description +=
                ',\n' + this.translateService.instant('JOURNAL.INITIATOR_SUFFIX', { fullname: item.fullName });
            if (item.requesterBasicRole) {
                description += ` (${this.translateService.instant('USER.ROLE.' + item.requesterBasicRole)})`;
            }
            if (item.type === TimelineType.UPDATE_EMAIL_EVENT && item.delayedTime) {
                description +=
                    ',\n' +
                    this.translateService.instant('STATE_DETAILS.VERIFIED_AT', {
                        date: format(new Date(item.delayedTime), 'dd.MM.yyyy, HH:mm', { locale: de }),
                    });
            }
        }
        return description;
    }
}
